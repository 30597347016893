module.exports = [{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"showCaptions":true,"markdownCaptions":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-167613160-1","anonymize":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Tovly Deutsch – Software Engineer, Researcher, & Filmmaker.","short_name":"Tovly Deutsch","description":"Tovly Deutsch – Software Engineer, Researcher, & Filmmaker. Creator of Bulk Sheet Manager and Attendance Sorter Google Sheets Add-ons","start_url":"/","background_color":"#191e38","theme_color":"#6574cd","display":"standalone","icon":"static/favicon.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"a7bfaca379509024097f15b7a1e13862"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
